<template>
    <div class="container my-5">
        Single Request
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import request from "../../apis/request"
import scrollTop from "../utils/scrollTop"

export default {
    components: {},
    data() {
        return {
            requests: [],
        }
    },
    async mounted() {
        if (!this.isMaterialTester) {
            return this.$router.push({ name: "dashboard" })
        }
        scrollTop()
        // await this.fetchRequest()
    },
    methods: {
        async fetchRequest() {
            const res = await request.get("/material-tester-requests")
            const data = res.data
            this.requests = data.result
        },
    },
    computed: {
        ...mapState("auth", {
            user: "user",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
}
</script>

<style></style>
